 .Method {
   background-color: #DDDDDD;
   padding: 32px 0 64px;

   .text {
     margin-bottom: 32px;
   }

   .down-arrow {
    text-align: center;
     margin-bottom: 8px;
    img {
      width: 5%;
    }
   }

   .caption {
     text-align: center;
     margin: 8px 0;
   }

   .method-1 {
     margin-bottom: 16px;
     text-align: center;
     img {
       width: 30%;
       vertical-align: middle;
     }

     img.arrow {
       width: 5%;
       vertical-align: middle;
     }
   }

   .method-2 {
     margin-bottom: 16px;
     text-align: center;

     img {
       width: 100%;
     }
   }

   .method-3 {
     video {
       width: 100%;
     }
   }
   
 }