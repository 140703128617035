.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

h2.title {
  font-size: 32px;
  font-weight: bold;
  margin: 24px 0;
}

h3.subtitle {
  font-size: 24px;
  font-weight: bold;
  margin: 24px 0;
}

.App {
  padding: 32px 0;
  .main-title {
    margin-bottom: 16px;
    font-size: 32px;
    font-weight: bold;
  }

  .description {
    margin-bottom: 20px;
    .bold {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .video {
    margin-bottom: 64px;
    position: relative;
    padding-bottom: 56.25%; /*アスペクト比 16:9の場合の縦幅*/
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: none;
    }
  }

  .photos {
    .photo.col {
      padding: 1px;
      img {
        width: 100%;
        vertical-align: bottom;
      }
    }
  }

  .footer {
    font-size: 10px;
  }
}


