.Exhibition {
  padding: 32px 0 64px;

  .desc {
    margin-bottom: 32px;
  }

  .photos {
    img {
      width: 100%;
      margin-bottom: 12px;
    }

    .image {
      float: left;
    }

    .image.single {
      width: 100%;
    }

    .image.twin {
      width: 49%;
    }

    .image.twin.leftspace {
      margin-right: 2%;
    }

  }
}
