.Results {
  padding: 32px 0;

  .basic-videos {
    .element {
      width: 32%;
      float: left;
      margin-bottom: 4px;
    }

    .element.leftspace {
      margin-right: 2%;
    }

    .video-wrapper {
      overflow: hidden;
      border: 1px solid black;
      position: relative;
      width: 100%;
    }

    .video-wrapper:before {
      content: "";
      display: block;
      padding-top: 62.5%;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      vertical-align: bottom;
    }

    p.name {
      text-align: center;
      margin: 8px;
      font-weight: bold;
    }
  }

  .combination-videos {
    .element {
      width: 32%;
      float: left;
      margin-bottom: 4px;
    }

    .element.twin {
      width: 48%;
      float: left;
      margin-bottom: 4px;
    }

    .element.twin.leftspace {
      margin-right: 4%;
    }

    .element.single {
      width: 100%;
      float: left;
      margin-bottom: 4px;
      margin-right: 2%;
    }

    .operator {
      display: flex;
      align-items: center;
      width: 2%;
      float: left;

      img {
        width: 100%;
      }
    }

    .video-wrapper {
      overflow: hidden;
      border: 1px solid black;
      position: relative;
      width: 100%;
    }

    .video-wrapper:before {
      content: "";
      display: block;
      padding-top: 62.5%;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      vertical-align: bottom;
    }

    p.name {
      text-align: center;
      margin: 8px;
      font-weight: bold;
    }

    .group {
      margin-bottom: 32px;
    }
  }


  video.repeatable-video.hidden {
    opacity: 0;
  }

  video.repeatable-video {
    transition-property: opacity;
    transition-duration: 1s;
  }
}
